<script>
export default {
  name: 'LogoSanctuary',
  props: {
    fill: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
    },
    mode: {
      type: String,
      default: 'full', // full, brandmark, submark
    },
  },
}
</script>

<template>
  <!-- eslint-disable max-len -->
  <svg
    v-if="mode === 'brandmark'" id="Layer_1" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 431.01 576" style="enable-background:new 0 0 431.01 576;" xml:space="preserve"
  >
    <g>
      <g>
        <polygon
          :fill="fill ? '#FFA07D' : 'currentColor'" points="10.97,290.79 2.77,290.79 2.77,288.31 9.87,288.31 9.87,285.84 2.77,285.84 2.77,283.46
			10.97,283.46 10.97,280.98 0,280.98 0,293.27 10.97,293.27 		"
        />
        <path
          :fill="fill ? '#FFA07D' : 'currentColor'" d="M20.44,290.96c-1.7,0-3.3-0.5-4.61-1.43l-0.33-0.23l-1.22,2.26l0.25,0.18c1.58,1.14,3.63,1.74,5.93,1.74
			c4.97,0,5.72-2.48,5.72-3.96c0-2.76-2.07-3.48-5.42-3.74c-2.83-0.2-3.06-0.65-3.06-1.16c0-0.82,0.98-1.32,2.62-1.32
			c1.49,0,2.78,0.38,3.83,1.13l0.33,0.24l1.14-2.24l-0.25-0.18c-1.38-0.97-3.08-1.46-5.04-1.46c-3.41,0-5.45,1.46-5.45,3.91
			c0,2.76,2.16,3.49,5.4,3.74c2.85,0.21,3.08,0.65,3.08,1.16C23.38,290.72,21.78,290.96,20.44,290.96z"
        />
        <polygon
          :fill="fill ? '#FFA07D' : 'currentColor'" points="36.65,293.27 36.65,283.46 41.12,283.46 41.12,280.98 29.4,280.98 29.4,283.46 33.88,283.46
			33.88,293.27 		"
        />
        <path
          :fill="fill ? '#FFA07D' : 'currentColor'" d="M42.36,291.87c0,0.91,0.69,1.6,1.61,1.6c0.94,0,1.61-0.67,1.61-1.6c0-0.92-0.68-1.59-1.61-1.59
			S42.36,290.95,42.36,291.87z"
        />
        <path
          :fill="fill ? '#FFA07D' : 'currentColor'" d="M381.3,293.04h-8.85c0.21-1.35,1.13-1.67,3.55-2.19c3.11-0.67,5.35-1.16,5.35-3.92
			c0-1.79-0.95-3.93-5.48-3.93c-2.16,0-3.94,0.49-5.44,1.49l-0.25,0.17l1.09,2.28l0.34-0.22c1.2-0.8,2.66-1.2,4.34-1.2
			c1.75,0,2.64,0.45,2.64,1.33c0,0.78-0.64,1-3.18,1.56c-3.97,0.87-5.74,1.92-5.74,5.84v1.24h11.64V293.04z"
        />
        <path
          :fill="fill ? '#FFA07D' : 'currentColor'" d="M385.09,289.34c0,3.74,2.67,6.35,6.48,6.35c3.82,0,6.48-2.61,6.48-6.35c0-3.74-2.67-6.35-6.48-6.35
			C387.76,283,385.09,285.61,385.09,289.34z M387.86,289.34c0-2.28,1.49-3.82,3.72-3.82c2.22,0,3.71,1.53,3.71,3.82
			c0,2.28-1.49,3.82-3.71,3.82C389.35,293.16,387.86,291.62,387.86,289.34z"
        />
        <path
          :fill="fill ? '#FFA07D' : 'currentColor'" d="M401.74,289.34c0,3.74,2.67,6.35,6.48,6.35c3.82,0,6.48-2.61,6.48-6.35c0-3.74-2.67-6.35-6.48-6.35
			C404.41,283,401.74,285.61,401.74,289.34z M404.51,289.34c0-2.28,1.49-3.82,3.72-3.82c2.22,0,3.71,1.53,3.71,3.82
			c0,2.28-1.49,3.82-3.71,3.82C406,293.16,404.51,291.62,404.51,289.34z"
        />
        <path
          :fill="fill ? '#FFA07D' : 'currentColor'" d="M428.93,289.09c1.05-0.57,1.62-1.46,1.62-2.58c0-2.24-2.14-3.52-5.87-3.52c-3.74,0-5.89,1.28-5.89,3.52
			c0,1.11,0.57,2.01,1.62,2.58c-1.69,0.7-2.08,1.91-2.08,2.87c0,2.37,2.31,3.73,6.35,3.73c4.02,0,6.33-1.36,6.33-3.73
			C431.01,291,430.63,289.79,428.93,289.09z M425.31,288.02h-1.28c-1.19,0-2.47-0.32-2.47-1.23c0-1.16,2.18-1.28,3.12-1.28
			c0.93,0,3.1,0.12,3.1,1.28C427.79,287.69,426.51,288.02,425.31,288.02z M424.04,290.48h1.28c1.09,0,2.93,0.17,2.93,1.32
			c0,1.2-2.23,1.38-3.56,1.38c-1.34,0-3.58-0.18-3.58-1.38C421.11,290.65,422.94,290.48,424.04,290.48z"
        />
        <path
          :fill="fill ? '#FFA07D' : 'currentColor'" d="M6.98,209.25c0.08,0,0.17,0.01,0.25,0.01c2.71,0,4.53-2.1,6.28-4.13c1.67-1.94,3.37-3.94,5.83-3.82
			c1,0.05,1.76,0.34,2.25,0.87c0.48,0.52,0.7,1.26,0.65,2.2c-0.07,1.49-0.88,2.91-1.37,3.01c-1.35,0.37-2.56,0.6-3.63,0.81
			c-0.34,0.07-0.67,0.13-0.99,0.2l-0.4,0.08l-0.01,0.34l-0.01,0.36l-0.01,0.35l0.51,0.03c2.07,0.13,4.02,0.29,5.47,0.44l0.33,0.04
			l0.17-0.28c0.62-1.02,1.09-2.97,1.18-4.97c0.17-3.7-1.81-6.01-5.31-6.18c-2.98-0.18-4.94,2.08-6.87,4.3
			c-1.62,1.87-3.16,3.63-5.14,3.63l-0.16,0c-0.6-0.03-2.56-0.32-2.44-2.86c0.06-1.25,0.8-2.7,1.41-2.78
			c0.89-0.11,2.16-0.32,3.77-0.61l0.41-0.07l0.02-0.35l0.02-0.34l0.02-0.34l-0.46-0.07c-1.66-0.26-3.08-0.54-4.5-0.87l-0.36-0.08
			l-0.2,0.32c-0.78,1.25-1.27,3.02-1.35,4.85c0,0.04,0,0.08-0.01,0.12v0.55C2.42,207.44,4.74,209.15,6.98,209.25z"
        />
        <path
          :fill="fill ? '#FFA07D' : 'currentColor'" d="M22.4,176.8c0.65,0.39,1.04,0.81,1.44,1.27c0.43,0.49,0.77,0.97,0.99,1.42l0.07,0.15l0.47,0.11l0.1,0.02
			l0.33,0.07l1.34-5.97l-0.33-0.07l-0.27-0.06l-0.38-0.08l-0.21,0.26c-0.75,0.95-1.22,1.43-1.79,1.82
			c-0.55,0.36-0.63,0.31-0.89,0.16l-3.35-2.07c0.58-2.05,1.05-4.09,1.45-6.36c1.89-0.18,3.84-0.35,5.8-0.51
			c0.05,0,0.09-0.01,0.12-0.01c0,0.04,0,0.09-0.01,0.15l-0.3,2.18l0.71,0.16l0.08,0.02l0.33,0.07l1.61-7.14l-0.33-0.07l-0.12-0.03
			l-0.66-0.15l-0.48,1.32c-0.1,0.27-0.11,0.27-0.31,0.29c-6.14,0.69-12.52,1.33-18.16,1.89l-2.52,0.25l0.05,1.48L22.4,176.8z
			 M20.09,167.59c-0.36,1.94-0.77,3.76-1.26,5.53l-7.47-4.63c0.74-0.08,1.49-0.16,2.25-0.24C15.82,168.02,17.93,167.8,20.09,167.59z
			"
        />
        <path
          :fill="fill ? '#FFA07D' : 'currentColor'" d="M18.16,135.82c0.02-0.03,0.04-0.06,0.05-0.09l15.04,6.52c0.27,0.12,0.5,0.32,0.76,0.67
			c0.3,0.42,0.52,0.79,0.75,1.26l0.06,0.12l0.77,0.33l2.28-5.25l-0.31-0.14l-0.23-0.1L36.97,139l-0.25,0.22
			c-0.77,0.7-1.44,1.23-2.11,1.67c-0.43,0.3-0.65,0.33-1.06,0.15l-13.45-5.83c7.37-0.9,13.98-1.59,20.18-2.11l0.31-0.03l1.06-2.45
			l-16.28-7.06c-0.38-0.16-0.56-0.37-0.77-0.7c-0.22-0.34-0.48-0.74-0.68-1.2l-0.06-0.13l-0.48-0.21l-0.02-0.05l-0.01,0.03
			l-0.31-0.14l-2.21,5.09l0.31,0.14l0.28,0.12l0.36,0.16l0.25-0.23c0.61-0.56,1.23-1.04,1.89-1.47c0.53-0.37,0.82-0.36,1.15-0.21
			l12.97,5.62c-5.72,0.67-11.98,1.3-19.55,1.96l-0.31,0.03l-1.84,4.23l1.07,0.47L18.16,135.82z"
        />
        <path
          :fill="fill ? '#FFA07D' : 'currentColor'" d="M43.52,105.91c2.15,1.48,4.55,2.29,6.75,2.29c2.85,0,5.29-1.36,7.05-3.92c1.28-1.86,2.12-3.69,2.73-5.94
			l0.09-0.32l-0.25-0.21c-1.5-1.32-2.34-1.91-4.04-3.23l-0.44-0.33l-0.19,0.27l-0.21,0.3l-0.21,0.3l0.25,0.31
			c1.39,1.72,1.53,2.2,2.02,2.95c0.88,1.75,0.22,3.78-0.61,4.99c-2.19,3.18-6.57,3.08-11.44-0.27c-2.44-1.68-4.04-3.37-4.74-5.04
			c-0.71-1.69-0.49-3.39,0.66-5.05c0.68-0.99,2.45-3.25,4.09-2.91c1.33,0.51,2.45,0.87,3.76,1.53l0.41,0.21l0.2-0.29l0.21-0.3
			l0.2-0.28l-0.36-0.32c-1.44-1.29-2.5-2.35-4.24-3.74l-0.35-0.28l-0.33,0.3c-1.84,1.67-3.14,3.14-4.37,4.93
			c-1.88,2.73-2.35,5.49-1.42,8.21C39.48,102.22,41.18,104.3,43.52,105.91z"
        />
        <path
          :fill="fill ? '#FFA07D' : 'currentColor'" d="M63.42,70.62l1.13,1.34l0.24-0.24l0.26-0.26l0.26-0.26l-0.15-0.33c-0.63-1.44-1.37-3.13-2.06-4.79
			c0,0,0.01-0.03,0.07-0.09l2.63-2.65c4.29,4.25,8.54,8.58,12.64,12.88c0.1,0.1,0.14,0.16,0.15,0.16c0,0,0,0,0,0
			c-0.01,0.02-0.04,0.07-0.13,0.16l-1.55,1.73l0.82,0.83l5.86-5.75l-0.82-0.83l-1.78,1.54c-0.09,0.08-0.13,0.11-0.13,0.11
			c-0.02-0.01-0.07-0.05-0.18-0.16c-4.41-4.34-8.66-8.67-12.62-12.88l2.85-2.75c0,0,0.03,0,0.11,0.05c1.46,0.69,2.99,1.48,4.42,2.3
			l0.35,0.2l0.26-0.25l0.26-0.26l0.24-0.24l-0.36-0.37c-1.03-1.05-2.44-2.48-3.77-3.92l-0.37-0.4L60.33,66.99l0.32,0.37
			C61.6,68.46,62.56,69.59,63.42,70.62z"
        />
        <path
          :fill="fill ? '#FFA07D' : 'currentColor'" d="M104.06,54.51c0.4,0.08,0.8,0.13,1.21,0.13c1.4,0,2.86-0.5,4.33-1.47c3.97-2.63,4.23-6.6,0.79-11.8l-4.78-7.2
			c-0.28-0.42-0.28-0.56-0.25-0.69c0.16-0.73,0.33-1.31,0.61-2.02l0.07-0.17l-0.24-0.36l-0.1-0.15l-0.19-0.28l-5.24,3.48l0.19,0.29
			l0.15,0.23l0.21,0.32l0.33-0.05c1.1-0.17,2.02-0.24,2.89-0.21c0.25,0.01,0.29,0.03,0.54,0.4l4.74,7.15
			c2.9,4.37,2.94,7.39,0.15,9.25c-2.76,1.83-5.33,1.18-7.42-1.89c-2.39-3.44-4.38-6.49-6.82-10.28c-0.05-0.07-0.07-0.12-0.09-0.15
			c0.03-0.02,0.06-0.05,0.08-0.06l2.44-1.83l-0.66-1l-6.72,4.46l0.19,0.28l0.12,0.18l0.34,0.52l1.45-0.76
			c0.09-0.05,0.14-0.06,0.14-0.07c0.02,0.02,0.06,0.06,0.13,0.17c2.27,3.32,4.18,6.14,6.64,9.9
			C100.63,52.89,102.23,54.13,104.06,54.51z"
        />
        <path
          :fill="fill ? '#FFA07D' : 'currentColor'" d="M148.36,32.34l-0.27-0.63l-1.35,0.38c-0.27,0.08-0.29,0.06-0.41-0.08c-4.09-4.53-8.3-9.28-12.01-13.48
			l-1.82-2.06l-1.17,0.9l1.24,17.84c0.06,0.75-0.05,1.31-0.18,1.91c-0.14,0.63-0.33,1.19-0.58,1.64l-0.08,0.15l0.19,0.44l0.04,0.09
			l0.14,0.31l5.62-2.4l-0.13-0.31l-0.11-0.25l-0.15-0.36L137,36.43c-1.21-0.05-1.88-0.15-2.52-0.39c-0.62-0.24-0.63-0.34-0.65-0.64
			l-0.28-3.93c2.02-0.74,3.94-1.55,6-2.53c1.28,1.46,2.55,2.94,3.8,4.41c0.03,0.04,0.06,0.07,0.08,0.1
			c-0.03,0.02-0.07,0.05-0.13,0.08l-1.94,1.03l0.28,0.66l0.04,0.09l0.13,0.31l6.73-2.87l-0.13-0.31l0.3-0.13L148.36,32.34z
			 M138.69,27.96c-1.77,0.84-3.49,1.56-5.22,2.2l-0.62-8.76l1.48,1.66C135.83,24.73,137.25,26.32,138.69,27.96z"
        />
        <path
          :fill="fill ? '#FFA07D' : 'currentColor'" d="M180.15,22.77c-0.06,0.01-0.1,0.01-0.11,0.01c-0.01,0-0.01,0-0.01,0c-0.01,0-0.04-0.02-0.14-0.14
			c-2.39-2.61-4.64-5.21-6.87-7.94c1.61-1.34,2.87-3.52,2.35-5.8c-0.69-3.08-3.27-4.36-7.08-3.5l-7.08,1.6l0.07,0.33l-0.03,0.01
			l0.19,0.78l1.69-0.21c0.15-0.02,0.21-0.01,0.22-0.02c0.01,0.02,0.02,0.06,0.04,0.14c1.06,4.26,2.94,12.48,3.95,17.47
			c0.01,0.05,0.02,0.08,0.02,0.09c-0.03,0.01-0.08,0.04-0.17,0.07l-1.63,0.54l0.18,0.77l0,0l-0.03,0.02l0.03-0.01l0.08,0.33
			l7.54-1.7l-0.26-1.13l-2.51,0.38c-0.09,0.02-0.14,0.02-0.15,0.03c-0.01-0.02-0.03-0.08-0.05-0.18c-0.55-2.18-1.24-5.12-1.96-8.29
			l1.31-0.3l0.56-0.13c2.29,2.63,4.6,5.39,7.06,8.41l0.2,0.25l3.99-0.9l-0.25-1.11L180.15,22.77z M172.41,9.91
			c0.66,2.92-1.39,4.7-1.76,4.79c-0.53,0.12-1.52,0.3-2.5,0.45c-0.58-2.55-1.27-5.61-1.74-7.93c0.76-0.25,1.43-0.45,1.81-0.54
			c1.01-0.23,1.88-0.1,2.59,0.4C171.57,7.63,172.11,8.58,172.41,9.91z"
        />
        <path
          :fill="fill ? '#FFA07D' : 'currentColor'" d="M198.92,2.23c2.35,3.5,4.03,6.19,5.61,8.98c0.19,2.12,0.35,5.65,0.39,8.46c0,0.08,0,0.26-0.12,0.28
			l-2.08,0.22l0.07,1.17l7.57-0.36l-0.06-1.17l-2.11,0.02c-0.13,0-0.15-0.21-0.15-0.31c-0.19-2.74-0.32-6.18-0.37-8.4l3.6-7.86
			c0.17-0.38,0.37-0.71,0.66-1.08c0.44-0.56,0.53-0.66,1.06-1.17l0.11-0.11l-0.02-0.45l-0.01-0.11L213.06,0l-5.09,0.24l0.01,0.34
			L208,0.7l0.01,0.29l0.08,0.23l0.15,0.1c0.33,0.22,0.93,0.76,1.32,1.18c0.49,0.52,0.39,0.74,0.33,0.85l-2.91,6.24
			c-1.64-2.49-3.33-5.06-4.88-7.79c0,0,0-0.01-0.01-0.01l2.54-0.23l-0.05-1.17l-7.49,0.35l0.05,1.15l1.44,0.06
			C198.67,1.97,198.74,1.97,198.92,2.23z"
        />
        <path
          :fill="fill ? '#FFA07D' : 'currentColor'" d="M273.67,7.9c-3.11-0.86-5.95-0.4-8.18,1.33c-1.84,1.43-3.27,3.72-4.02,6.46c-1.28,4.65-0.46,10.71,6.05,12.51
			c1.7,0.47,3.28,0.69,4.97,0.69c0.5,0,1-0.02,1.53-0.06l0.33-0.02l0.12-0.3c0.76-1.86,1.24-2.99,1.92-5.02l0.18-0.53l-0.32-0.09
			l-0.36-0.1l-0.35-0.09l-0.2,0.33c-1.17,1.88-1.77,2.31-2.32,3.02c-1.36,1.4-3.5,1.44-4.91,1.06c-3.71-1.02-5.05-5.2-3.48-10.9
			c1.6-5.81,4.36-8.21,8.2-7.16c0.91,0.25,3.88,1.2,4.08,2.91c-0.05,1.39,0.07,2.49-0.14,3.97l-0.06,0.45l0.34,0.09l0.35,0.1
			l0.33,0.09l0.22-0.52c0.79-1.87,1.33-3.05,2.04-5.08l0.15-0.42l-0.39-0.22C277.58,9.23,275.76,8.48,273.67,7.9z"
        />
        <path
          :fill="fill ? '#FFA07D' : 'currentColor'" d="M298.34,39.49l0.15-0.3l0.12-0.24l0.18-0.35l-0.21-0.26c-0.75-0.95-1.1-1.52-1.35-2.16
			c-0.22-0.63-0.15-0.7,0.06-0.91l2.8-2.77c1.88,1.05,3.74,1.98,5.83,2.9c-0.27,1.86-0.56,3.79-0.87,5.75
			c-0.01,0.05-0.02,0.09-0.02,0.12c-0.04-0.01-0.08-0.02-0.14-0.05l-2.05-0.8l-0.31,0.64l-0.04,0.09l-0.15,0.31l6.56,3.23l0.15-0.3
			l0.3,0.15l-0.25-0.25l0.3-0.61l-1.17-0.77c-0.24-0.16-0.24-0.17-0.2-0.37c0.74-5.84,1.55-11.73,2.34-17.44l0.44-3.16l-1.45-0.3
			l-12.72,12.56c-0.54,0.54-1.05,0.83-1.57,1.11c-0.57,0.3-1.11,0.52-1.61,0.63l-0.16,0.04l-0.22,0.44l-0.04,0.08l-0.15,0.3
			L298.34,39.49z M301.04,31.57l6.25-6.17L307,27.51c-0.3,2.26-0.58,4.4-0.89,6.59C304.31,33.3,302.64,32.47,301.04,31.57z"
        />
        <path
          :fill="fill ? '#FFA07D' : 'currentColor'" d="M345.2,45.64c-0.31-1.43-1.28-2.78-2.82-3.91l-5.81-4.27l-0.69,0.92l1.29,1.15c0.09,0.09,0.13,0.13,0.14,0.13
			c-0.01,0.02-0.03,0.05-0.08,0.12c-4.08,5.72-7.45,10.31-10.62,14.43c-0.03,0.04-0.05,0.06-0.05,0.07
			c-0.03-0.01-0.08-0.03-0.17-0.08l-1.49-0.9l-0.69,0.92l5.93,4.37l0.43,0.31l0.68-0.93l-2-1.7c-0.11-0.09-0.15-0.14-0.16-0.14
			c0,0,0,0,0,0c0.01-0.02,0.03-0.07,0.11-0.18c1.12-1.62,2.71-3.82,4.48-6.24c0.43,0.36,0.87,0.72,1.31,1.06l0.21,0.16
			c0.04,0.03,0.07,0.06,0.11,0.08c1.53,1.13,3.15,1.63,4.69,1.45c1.59-0.19,3.04-1.1,4.17-2.64
			C345.16,48.52,345.51,47.07,345.2,45.64z M339.83,41.52c0.53,0.3,1.07,0.65,1.51,0.97c1.72,1.27,1.81,3.49,0.22,5.66
			c-1.01,1.37-2,2.21-2.95,2.5c-0.82,0.25-1.59,0.09-2.36-0.47c-0.51-0.37-1.06-0.94-1.65-1.68
			C336.57,45.81,338.46,43.3,339.83,41.52z"
        />
        <path
          :fill="fill ? '#FFA07D' : 'currentColor'" d="M376.8,105.98l0.27,0.19l0.28-0.16c1.77-0.98,2.91-1.8,4.69-2.94l0.47-0.3l-0.2-0.27l-0.22-0.3l-0.22-0.3
			l-0.36,0.13c-2.06,0.72-2.9,0.93-3.76,1.14c-1.94,0.24-3.64-1.07-4.5-2.25c-1.13-1.56-1.32-3.39-0.56-5.28
			c0.75-1.87,2.36-3.74,4.66-5.4c4.89-3.53,8.55-3.68,10.88-0.45c0.55,0.76,2.28,3.36,1.37,4.82c-0.92,1.07-1.53,2.01-2.6,3.02
			l-0.33,0.32l0.21,0.29l0.22,0.3l0.2,0.28l0.44-0.24c1.71-0.93,2.97-1.57,4.83-2.73l0.38-0.24l-0.17-0.41
			c-0.95-2.27-1.9-4-3.18-5.77c-4.09-5.66-9.91-4.65-14.11-1.76l0,0l-0.28,0.2c0,0,0,0-0.01,0l-0.29,0.21l0,0
			c-2.05,1.56-3.54,3.52-4.22,5.55c-0.87,2.62-0.39,5.31,1.4,7.78C373.44,103.25,374.88,104.66,376.8,105.98z"
        />
        <path
          :fill="fill ? '#FFA07D' : 'currentColor'" d="M389.16,124.15c-0.64-0.12-1.2-0.29-1.65-0.52l-0.15-0.08l-0.44,0.21l-0.09,0.04l-0.31,0.15l2.6,5.53
			l0.31-0.14l0.24-0.11l0.35-0.16l0.01-0.33c0.01-1.21,0.09-1.87,0.3-2.53c0.22-0.63,0.32-0.64,0.62-0.67l3.92-0.42
			c0.8,1.99,1.68,3.88,2.75,5.91c-1.41,1.32-2.85,2.65-4.28,3.95c-0.04,0.04-0.07,0.06-0.1,0.08c-0.02-0.03-0.05-0.07-0.08-0.13
			l-1.1-1.91l-0.66,0.31l-0.08,0.04l-0.31,0.15l3.11,6.62l0.31-0.15l0.1-0.05l0.62-0.29l-0.43-1.33c-0.09-0.27-0.07-0.29,0.07-0.42
			c4.35-4.22,8.8-8.44,13.11-12.52l1.94-1.84l-0.94-1.14l-17.79,1.86C390.33,124.35,389.74,124.26,389.16,124.15z M403.25,125.66
			c-1.6,1.54-3.13,3-4.69,4.48c-0.9-1.73-1.68-3.42-2.39-5.15l8.74-0.92C404.35,124.61,403.8,125.14,403.25,125.66z"
        />
        <path
          :fill="fill ? '#FFA07D' : 'currentColor'" d="M419.52,154.17l-15.87,4.06c-0.29,0.07-0.58,0.06-1.01-0.06c-0.5-0.15-0.9-0.31-1.36-0.54l-0.12-0.06
			l-0.81,0.21l1.28,5.04l0.12,0.51l0.33-0.08l0.24-0.06l0.38-0.09l0.06-0.33c0.18-1.02,0.38-1.85,0.64-2.61
			c0.16-0.49,0.31-0.66,0.74-0.77l14.18-3.63c-5.22,5.18-10.02,9.78-14.65,14.03l-0.23,0.21l0.66,2.59l17.18-4.39
			c0.4-0.1,0.67-0.05,1.03,0.08c0.41,0.14,0.84,0.3,1.27,0.53l0.12,0.06l0.52-0.13l0.04,0.02l-0.01-0.03l0.33-0.08l-1.37-5.38
			l-0.33,0.09l-0.3,0.08l-0.37,0.1l-0.06,0.33c-0.14,0.81-0.33,1.57-0.59,2.32c-0.19,0.62-0.42,0.78-0.78,0.87l-13.68,3.5
			c4.14-4.06,8.69-8.39,14.24-13.52l0.23-0.21l-1.14-4.47l-1.13,0.29l0.19,1.46C419.51,154.11,419.52,154.14,419.52,154.17z"
        />
        <path
          :fill="fill ? '#FFA07D' : 'currentColor'" d="M409.81,193.52c-0.55-0.35-1.01-0.71-1.35-1.08l-0.11-0.12l-0.49,0.03l-0.09,0.01l-0.34,0.02l0.39,6.1
			l0.34-0.02l0.27-0.02l0.39-0.03l0.12-0.3c0.46-1.13,0.78-1.72,1.21-2.25c0.43-0.5,0.53-0.48,0.82-0.4l3.79,1.04
			c0.02,2.16,0.15,4.24,0.39,6.51c-1.8,0.72-3.62,1.43-5.42,2.11c-0.05,0.02-0.09,0.03-0.12,0.04c-0.01-0.04-0.02-0.08-0.03-0.15
			l-0.33-2.17l-0.72,0.05l-0.09,0.01l-0.34,0.02l0.47,7.3l0.34-0.02l0.12-0.01l0.67-0.04l0.09-1.4c0.02-0.28,0.03-0.29,0.22-0.36
			c5.78-2.42,11.76-4.84,17.03-6.97l2.2-0.89l-0.46-1.41l-17.23-4.77C410.81,194.13,410.3,193.83,409.81,193.52z M422.37,200.06
			c-2.06,0.85-4.02,1.65-6.02,2.46c-0.2-1.94-0.31-3.8-0.34-5.66l8.47,2.34L422.37,200.06z"
        />
        <path
          :fill="fill ? '#FFA07D' : 'currentColor'" d="M15.42,392.81c6.32-0.64,9.72-4.67,9.1-10.79l-0.84-8.3l-1.14,0.1l0.01,1.74c0,0.11-0.01,0.17,0,0.19
			c-0.03,0.01-0.07,0.01-0.15,0.02c-5.83,0.68-11.83,1.29-17.82,1.81c-0.04,0-0.09,0.01-0.1,0.03c-0.01-0.03-0.03-0.09-0.05-0.2
			L4.1,375.7l-0.79,0.09l0,0l-0.01-0.03l0,0.03l-0.34,0.04l0.84,8.3c0.28,2.74,1.56,5.12,3.61,6.69c1.77,1.36,4.04,2.07,6.57,2.07
			C14.45,392.89,14.94,392.86,15.42,392.81z M22.84,378.86c0.21,1.19,0.36,2.22,0.45,3.05c0.43,4.29-2.7,7.19-8.38,7.77h0
			c-2.68,0.27-5.14-0.17-6.94-1.24c-1.72-1.02-2.77-2.59-2.95-4.4c-0.12-1.19-0.15-2.51-0.15-3.35
			C9.94,380.12,16,379.47,22.84,378.86z"
        />
        <path
          :fill="fill ? '#FFA07D' : 'currentColor'" d="M32.27,419.93c-0.81-2.65-2.48-4.59-4.83-5.61c-2.32-1.01-5.17-1.05-8.22-0.12
			c-2.95,0.91-5.21,2.47-6.52,4.52c-1.33,2.08-1.62,4.52-0.84,7.06c0.81,2.65,2.48,4.6,4.83,5.62c1.2,0.52,2.55,0.79,3.99,0.79
			c1.34,0,2.76-0.23,4.22-0.68C30.96,429.65,33.85,425.11,32.27,419.93z M13.45,421.84c1.08-2.02,3.52-3.65,7.07-4.72
			c1.68-0.51,3.23-0.76,4.61-0.76c3.13,0,5.19,1.29,5.93,3.72c0.41,1.34,0.29,2.63-0.35,3.83c-1.08,2-3.52,3.62-7.07,4.67
			c-5.61,1.67-9.45,0.59-10.55-2.96C12.69,424.31,12.81,423.04,13.45,421.84z"
        />
        <path
          :fill="fill ? '#FFA07D' : 'currentColor'" d="M34.52,473.08c0,0,0.02-0.04,0.13-0.12c5.19-3.76,9.83-7.04,14.6-10.32c0.08-0.05,0.16-0.07,0.18-0.07
			c0,0,0,0,0,0c0.01,0,0.07,0.04,0.15,0.17l1.03,1.55l1.01-0.54l-2.32-4.25l-15.66,3.33c3.57-3.71,7.15-7.39,10.92-11.2l0.27-0.28
			l-0.99-1.81l-0.15-0.27l-1.19-2.17l-1.01,0.56l0.75,1.69c0.06,0.16,0.06,0.22,0.06,0.22c0,0.01-0.04,0.05-0.18,0.11l-14.37,5.55
			c-0.63,0.25-0.84,0.27-1.18,0.27c-0.92-0.01-1.68-0.09-2.21-0.22l-0.14-0.04l-0.77,0.43l2.47,4.52l0.3-0.16l0.14-0.08l0.39-0.21
			l0.03-0.27c0.09-0.72,0.23-1.24,0.57-2.15c0.21-0.53,0.34-0.71,0.88-0.93l12.18-4.72c-3.93,4.16-8.02,8.34-12.18,12.44l-0.28,0.28
			l0.17,0.31l0.11,0.2l0.17,0.31l17.13-3.64c-3.67,2.64-7.66,5.44-12.43,8.7c-0.04,0.03-0.07,0.04-0.08,0.05
			c-0.02-0.02-0.04-0.05-0.07-0.1l-1.3-1.99l-1.01,0.56l3.44,6.28l0.99-0.54l-0.52-1.31C34.52,473.11,34.52,473.08,34.52,473.08z"
        />
        <path
          :fill="fill ? '#FFA07D' : 'currentColor'" d="M67.3,485.34L63,479.92l-0.91,0.72l0.97,1.42c0.07,0.1,0.09,0.14,0.1,0.14c-0.01,0.02-0.04,0.06-0.12,0.13
			c-4.69,3.84-9.27,7.48-14,11.13c-0.08,0.06-0.13,0.08-0.13,0.1c-0.02-0.02-0.06-0.05-0.14-0.14l-1.16-1.26l-0.91,0.71L51,498.3
			l0.9-0.73l-0.97-1.42c-0.05-0.07-0.08-0.12-0.09-0.13c0.02-0.02,0.06-0.05,0.12-0.1c4.62-3.83,9.21-7.47,14.02-11.14
			c0.08-0.06,0.12-0.09,0.13-0.1c0.02,0.02,0.06,0.05,0.12,0.12l1.16,1.26L67.3,485.34z"
        />
        <path
          :fill="fill ? '#FFA07D' : 'currentColor'" d="M88.34,507.1l-0.23,0.25l-0.21,0.23l-0.26,0.29l0.13,0.31c0.33,0.76,0.58,1.5,0.78,2.26
			c0.18,0.62,0.08,0.89-0.17,1.16l-9.5,10.45c1.22-5.65,2.64-11.77,4.46-19.12l0.08-0.3l-3.42-3.1l-0.79,0.86l0.96,1.11
			c0.02,0.03,0.05,0.05,0.07,0.08l-11.02,12.12c-0.2,0.22-0.47,0.37-0.88,0.5c-0.5,0.15-0.92,0.24-1.43,0.3l-0.14,0.01l-0.55,0.63
			l4.23,3.85l0.23-0.25l0.17-0.19l0.26-0.29l-0.13-0.31c-0.41-0.95-0.69-1.76-0.9-2.53c-0.14-0.5-0.1-0.73,0.2-1.05l9.85-10.83
			c-1.51,7.19-2.99,13.67-4.52,19.76l-0.07,0.31l1.97,1.79l11.93-13.11c0.27-0.3,0.53-0.41,0.9-0.5c0.42-0.11,0.87-0.21,1.36-0.25
			l0.14-0.01l0.35-0.39l0.05-0.01l-0.02-0.02l0.23-0.25L88.34,507.1z"
        />
        <path
          :fill="fill ? '#FFA07D' : 'currentColor'" d="M107.37,523.33l1.38,1.04c0.09,0.07,0.13,0.1,0.14,0.1h0c-0.01,0.02-0.02,0.07-0.08,0.17
			c-3.22,5.13-6.41,10.04-9.74,14.99c-0.06,0.08-0.09,0.12-0.1,0.13c-0.03-0.01-0.08-0.03-0.18-0.09l-1.5-0.83l-0.65,0.96l5.82,3.78
			l0.63-0.97l-1.38-1.04c-0.07-0.05-0.11-0.09-0.13-0.09c0.01-0.03,0.04-0.07,0.08-0.13c3.18-5.11,6.37-10.02,9.75-15.01
			c0.06-0.08,0.09-0.12,0.09-0.14c0.02,0.01,0.07,0.03,0.16,0.08l1.5,0.83l0.64-0.97l-5.81-3.77L107.37,523.33z"
        />
        <path
          :fill="fill ? '#FFA07D' : 'currentColor'" d="M139.56,539.38c-3.04-1.33-5.84-1.28-8.33,0.16c-2,1.15-3.73,3.21-4.86,5.81c-1.07,2.46-1.41,5.03-0.94,7.24
			c0.57,2.72,2.36,4.8,5.15,6.02c2.07,0.9,4.02,1.38,6.35,1.55l0.33,0.02l0.16-0.28c1.03-1.75,1.66-3.27,2.62-5.16l0.24-0.49
			l-0.3-0.13l-0.34-0.15l-0.33-0.15l-0.25,0.3c-1.39,1.65-2.07,2.5-2.73,3.13c-1.55,1.19-3.67,0.93-5.01,0.34
			c-3.53-1.55-4.26-5.87-1.89-11.29c1.19-2.72,2.55-4.61,4.06-5.61c1.52-1.01,3.23-1.12,5.08-0.31c0.86,0.38,3.67,1.74,3.63,3.46
			c-0.24,1.36-0.39,2.34-0.8,3.79l-0.12,0.44l0.33,0.14l0.34,0.15l0.31,0.13l0.27-0.44c0.99-1.65,1.84-2.69,2.86-4.65l0.2-0.4
			l-0.36-0.26C143.25,541.25,141.56,540.25,139.56,539.38z"
        />
        <path
          :fill="fill ? '#FFA07D' : 'currentColor'" d="M176.67,571.46l-1.31-0.48c-0.27-0.1-0.27-0.12-0.29-0.31c-0.64-5.89-1.21-11.85-1.77-17.62l-0.29-3.08
			l-1.49,0.04l-9.47,15.18c-0.4,0.65-0.82,1.04-1.28,1.44c-0.48,0.43-0.96,0.76-1.42,0.99l-0.16,0.07l-0.1,0.48l-0.02,0.09
			l-0.07,0.33l5.95,1.36l0.08-0.33l0.06-0.26l0.09-0.38l-0.26-0.2c-0.96-0.76-1.43-1.23-1.81-1.8c-0.36-0.56-0.31-0.64-0.16-0.9
			l2.08-3.35c2.08,0.59,4.11,1.07,6.35,1.48c0.17,1.84,0.33,3.79,0.48,5.81c0.01,0.05,0.01,0.09,0.01,0.12c-0.04,0-0.09,0-0.15-0.01
			l-2.17-0.3l-0.16,0.69l-0.02,0.1l-0.08,0.33l7.14,1.63l0.07-0.33l0.02-0.11L176.67,571.46z M171.28,562.9
			c-1.93-0.36-3.75-0.78-5.53-1.29l4.65-7.46l0.25,2.5C170.87,558.79,171.08,560.82,171.28,562.9z"
        />
        <path
          :fill="fill ? '#FFA07D' : 'currentColor'" d="M215.56,555.25l-5.53-0.22l-0.02,0.34l-0.02,0.31l-0.02,0.38l0.31,0.16c0.73,0.37,1.4,0.77,2.05,1.23
			c0.53,0.36,0.63,0.63,0.61,1l-0.58,14.11c-2.68-5.09-5.52-10.7-8.86-17.52l-0.14-0.28l-4.61-0.19l-0.04,1.17l1.44,0.23
			c0.04,0.01,0.07,0.01,0.1,0.02l-0.68,16.37c-0.01,0.3-0.12,0.58-0.35,0.95c-0.29,0.43-0.56,0.77-0.9,1.14l-0.1,0.1l-0.03,0.84
			l5.72,0.23l0.01-0.34l0.01-0.25l0.01-0.39l-0.3-0.16c-0.93-0.47-1.66-0.9-2.32-1.36c-0.43-0.29-0.54-0.49-0.52-0.93l0.6-14.63
			c3.49,6.53,6.51,12.45,9.23,18.05l0.14,0.28l2.66,0.11l0.73-17.72c0.02-0.41,0.14-0.65,0.37-0.96c0.22-0.31,0.52-0.7,0.87-1.06
			l0.11-0.1l0.02-0.54l0.03-0.03l-0.03,0L215.56,555.25z"
        />
        <path
          :fill="fill ? '#FFA07D' : 'currentColor'" d="M278.49,565.09c-0.08,0.01-0.11,0.01-0.12,0.01c0,0,0,0,0,0c-0.01,0-0.04-0.02-0.14-0.13
			c-2.51-2.51-4.88-5-7.23-7.61c1.55-1.42,2.71-3.66,2.09-5.91c-0.42-1.53-1.28-2.58-2.56-3.13c-1.25-0.53-2.82-0.54-4.67-0.04
			l-7.01,1.93l0.09,0.33l-0.08,0.02l0.09,0l0.2,0.75l1.69-0.3c0.14-0.02,0.2-0.02,0.21-0.03c0.01,0.02,0.02,0.05,0.05,0.13
			c1.25,4.18,3.5,12.31,4.75,17.28c0.01,0.05,0.02,0.08,0.03,0.09c-0.03,0.02-0.08,0.04-0.17,0.08l-1.6,0.61l0.2,0.76l-0.04,0.03
			l0.04-0.01l0.09,0.33l6.96-1.91l0.51-0.14l-0.31-1.12l-2.49,0.5c-0.09,0.02-0.14,0.03-0.15,0.03c-0.01-0.03-0.03-0.08-0.06-0.18
			c-0.66-2.18-1.49-5.08-2.35-8.19l1.37-0.38l0.49-0.13c2.32,2.43,4.76,5.08,7.43,8.07l0.21,0.25l3.95-1.08l-0.31-1.09
			L278.49,565.09z M269.84,556.06c-0.47,0.92-1.05,1.34-1.19,1.4l-0.01,0c-0.51,0.14-1.47,0.36-2.47,0.57
			c-0.72-2.64-1.53-5.57-2.11-7.85c0.74-0.28,1.41-0.52,1.78-0.62c2.69-0.74,3.85,1.24,4.34,3.03
			C270.59,554.11,270.22,555.32,269.84,556.06z"
        />
        <path
          :fill="fill ? '#FFA07D' : 'currentColor'" d="M308.73,547.12l-0.21-0.51l-0.3,0.14l-0.34,0.16l-0.34,0.16l0.05,0.38c0.24,1.98,0.34,3.83,0.32,5.66
			c0,0.15-0.01,0.2-0.01,0.2c0,0,0,0,0,0c0,0-0.03,0.02-0.15,0.07l-4.25,1.78c-1.61-3.26-2.79-5.71-3.91-8.04l3.32-1.57
			c0.05-0.02,0.08-0.04,0.09-0.05c0.04,0.03,0.09,0.08,0.13,0.12c0.79,0.78,1.79,1.93,2.3,2.59l0.26,0.33l0.34-0.16l0.33-0.16
			l0.3-0.14l-0.2-0.46c-0.91-2.06-1.88-4.1-2.87-6.05l-0.23-0.45l-0.3,0.14l-0.29,0.14l-0.35,0.16l0.05,0.38
			c0.11,0.87,0.13,1.59,0.07,2.2c-0.01,0.12-0.03,0.25-0.03,0.28c0,0-0.02,0.02-0.08,0.05l-3.35,1.59
			c-1.02-2.16-2.06-4.41-3.32-7.19c0.64-0.39,1.26-0.73,1.84-1.01l2.28-1.07c0.02,0.02,0.05,0.05,0.1,0.1
			c1.2,1.17,2.28,2.33,3.29,3.54l0.26,0.31l0.33-0.16l0.3-0.14l0.31-0.14l-0.16-0.45c-0.54-1.52-1.17-3.15-1.93-4.99l-0.21-0.51
			l-11.04,5.23l-0.47,0.23l0.15,0.3l-0.02,0.01l0.02,0l0.34,0.73l1.63-0.59c0.1-0.04,0.16-0.05,0.18-0.07
			c0.01,0.02,0.04,0.06,0.07,0.13c2.6,5.28,5.18,10.72,7.67,16.19c0.02,0.04,0.03,0.07,0.04,0.09c-0.03,0.02-0.08,0.06-0.15,0.1
			l-1.45,0.87l0.34,0.7l-0.06,0.06l0.07-0.04l0.15,0.31l10.92-5.18l0.42-0.2l-0.15-0.45C310.1,550.68,309.42,548.84,308.73,547.12z"
        />
        <path
          :fill="fill ? '#FFA07D' : 'currentColor'" d="M334.96,537.29c-0.13,0.08-0.18,0.1-0.18,0.1c-0.01-0.01-0.05-0.05-0.13-0.16c-1.17-1.58-2.76-3.78-4.49-6.23
			c0.47-0.29,0.94-0.58,1.39-0.88l0.25-0.17c0.04-0.03,0.07-0.05,0.11-0.07c1.56-1.09,2.55-2.47,2.87-3.99
			c0.33-1.57-0.08-3.23-1.18-4.79c-1.93-2.75-5.09-3.08-8.25-0.86l-5.9,4.14l0.66,0.95l1.5-0.86c0.11-0.06,0.17-0.08,0.17-0.09
			c0.01,0.01,0.04,0.04,0.09,0.11c4.07,5.61,7.35,10.27,10.3,14.66c0.03,0.04,0.04,0.07,0.05,0.07c-0.02,0.02-0.05,0.07-0.13,0.14
			l-1.32,1.13l0.66,0.94l6.45-4.53l-0.66-0.95L334.96,537.29z M331.16,523.05c0.98,1.39,1.46,2.6,1.43,3.6
			c-0.02,0.84-0.42,1.54-1.2,2.08c-0.51,0.36-1.22,0.7-2.12,1.03c-1.94-2.77-3.69-5.3-4.95-7.19c0.44-0.4,0.95-0.81,1.4-1.12
			c0.75-0.53,1.58-0.73,2.42-0.58C329.24,521.06,330.31,521.83,331.16,523.05z"
        />
        <path
          :fill="fill ? '#FFA07D' : 'currentColor'" d="M364.96,501.17l-6.15-6.06c-0.36-0.35-0.39-0.49-0.39-0.62c0-0.75,0.05-1.35,0.17-2.02l0.04-0.27l-0.31-0.3
			l-0.12-0.12l-0.24-0.24l-4.42,4.48l0.24,0.24l0.2,0.19l0.28,0.27l0.32-0.11c0.99-0.37,1.9-0.63,2.8-0.8
			c0.23-0.05,0.27-0.04,0.6,0.28l6.1,6.02c1.95,1.92,3.08,3.67,3.36,5.19c0.26,1.4-0.17,2.65-1.33,3.83
			c-2.33,2.36-4.97,2.25-7.65-0.32c-3-2.84-5.58-5.42-8.78-8.66c-0.06-0.06-0.1-0.1-0.12-0.13c0.02-0.03,0.05-0.06,0.06-0.08
			l2.02-2.29l-0.86-0.83l-5.67,5.74l0.24,0.24l0.14,0.14l0.46,0.45l1.26-1.04c0.08-0.06,0.12-0.09,0.12-0.1
			c0.02,0.01,0.07,0.05,0.16,0.14c2.93,2.81,5.38,5.19,8.52,8.33c1.73,1.75,3.56,2.64,5.43,2.64h0c1.81,0,3.55-0.83,5.15-2.46
			c1.67-1.69,2.37-3.54,2.1-5.5C368.42,505.44,367.17,503.35,364.96,501.17z"
        />
        <path
          :fill="fill ? '#FFA07D' : 'currentColor'" d="M389.87,473.41c-1.76-1.2-4.39-1.67-6.85,0.23c0.25-1.87-0.52-3.61-2.13-4.7c-1.16-0.79-2.45-1.01-3.73-0.64
			c-1.31,0.38-2.54,1.38-3.56,2.88l-0.94,1.38l-0.16,0.17l0.08-0.06l-3.07,4.52l0.96,0.64l1.1-1.33c0.08-0.09,0.12-0.13,0.12-0.15
			c0.02,0.01,0.06,0.03,0.12,0.08c5.03,3.31,10.83,7.25,14.8,10.06c0.06,0.04,0.09,0.06,0.1,0.07c-0.01,0.03-0.03,0.08-0.08,0.17
			l-0.84,1.52l0.66,0.46l0,0.01l0-0.01l0.28,0.19l4.02-5.92C393.42,479.09,393.09,475.59,389.87,473.41z M379.56,471.69
			c1.72,1.17,2.28,2.84,1.39,4.16l-0.45,0.67l-0.32,0.31l0.19-0.12l-0.86,1.26c-2.05-1.4-4.09-2.82-6.07-4.22
			c0.48-0.84,0.92-1.48,1.05-1.68c0.58-0.86,1.29-1.32,2.13-1.39C377.47,470.6,378.46,470.94,379.56,471.69z M380.54,478.67
			l1.14-1.76c0.72-1.11,1.58-1.78,2.57-1.98c1.47-0.3,3.02,0.47,4.05,1.17c2.69,1.82,3.14,3.74,1.47,6.2
			c-0.26,0.38-0.76,1.01-1.29,1.66C386.1,482.4,383.37,480.58,380.54,478.67z"
        />
        <path
          :fill="fill ? '#FFA07D' : 'currentColor'" d="M404.82,442.41l-0.49-0.28l-0.14,0.31l-0.16,0.35l-0.16,0.34l0.29,0.25c1.48,1.32,3.16,3,4.49,4.48
			c0.07,0.07,0.07,0.1,0.07,0.1c0,0,0,0.03-0.04,0.12l-1.97,3.9c-5.96-2.59-11.31-5.01-16.35-7.43c-0.06-0.03-0.08-0.04-0.08-0.04
			c0,0-0.01-0.05,0.03-0.16l0.73-1.99l-1.06-0.47l-3.01,6.63l1.05,0.47l0.87-1.52c0.04-0.07,0.06-0.1,0.07-0.11
			c0.01,0,0.05,0.01,0.11,0.04c5.28,2.29,10.87,4.83,16.18,7.34c0.2,0.09,0.24,0.13,0.24,0.12c0,0.01,0,0.06-0.04,0.18l-0.48,1.48
			l0.71,0.33l-0.01,0.06l0.02-0.05l0.31,0.14l4.99-11l-0.39-0.24C409,444.76,407.1,443.66,404.82,442.41z"
        />
        <path
          :fill="fill ? '#FFA07D' : 'currentColor'" d="M418.83,419.18c-0.03,0.08-0.05,0.13-0.05,0.15c-0.03,0-0.08-0.01-0.16-0.03
			c-5.85-1.52-11.49-3.11-17.24-4.86c-0.09-0.03-0.16-0.05-0.16-0.05c0-0.03,0.01-0.08,0.03-0.17l0.35-1.69l-1.12-0.31l-1.88,6.66
			l1.12,0.31l0.59-1.62c0.04-0.11,0.06-0.15,0.06-0.16c0.02-0.01,0.07,0,0.18,0.02c5.89,1.57,11.53,3.15,17.21,4.84
			c0.1,0.03,0.15,0.07,0.16,0.05c0,0.03-0.01,0.08-0.03,0.2l-0.35,1.69l1.12,0.31l1.88-6.67l-1.12-0.31L418.83,419.18z"
        />
        <path
          :fill="fill ? '#FFA07D' : 'currentColor'" d="M426.02,379.72l-0.32-0.07c-2-0.46-3.19-0.64-5.25-0.99l-0.54-0.09l-0.04,0.33l-0.04,0.37l-0.04,0.36
			l0.36,0.15c2,0.84,2.55,1.26,3.35,1.7c1.5,1.05,1.99,3.11,1.82,4.68c-0.42,3.83-4.34,5.8-10.22,5.17
			c-2.95-0.32-5.16-1.07-6.56-2.21c-1.42-1.15-2.03-2.76-1.81-4.76c0.1-0.93,0.58-4.02,2.23-4.49c1.42-0.18,1.97-0.42,3.42-0.45
			l0.46-0.01l0.03-0.31l0.04-0.41l0.04-0.34l-0.62-0.15c-1.95-0.47-2.65-0.85-4.74-1.22l-0.44-0.07l-0.14,0.42
			c-0.83,2.33-1.28,4.25-1.52,6.41c-0.36,3.29,0.52,5.95,2.63,7.9c1.69,1.57,4.18,2.6,7,2.91c0.52,0.06,1.05,0.09,1.57,0.09
			c2.1,0,9-0.58,9.82-8.05c0.25-2.24,0.12-4.25-0.41-6.52L426.02,379.72z"
        />
      </g>
      <g>
        <path
          :fill="fill ? '#FFA07D' : 'currentColor'" d="M283.65,296.07c1.58,3.25,4.3,9.57,6.31,14.3c0.68,1.6,2.16,2.58,3.87,2.58c0.16,0,0.33-0.01,0.5-0.03
			c1.91-0.22,3.35-1.58,3.69-3.47l0.64-3.58c1.48-8.22,3.01-16.72,3.82-25.15l0.08-0.87l-0.84-0.25
			c-13.44-3.97-28.21-6.08-42.7-6.08c-7.28,0-14.09,1.05-20.33,3.09c-3.34-1.51-6.74-2.91-10.16-4.23c-0.8-0.31-1.6-0.61-2.41-0.91
			c0.7-1.62,1.33-3.29,1.91-5.02c2.99-8.19,4.51-17.14,4.51-26.67c0-17.1-4.91-32.61-13.85-43.98c2.36,0.3,4.71,0.79,7,1.46
			c12.26,3.93,22.35,13.37,22.35,27.73c0,1.21-0.12,2.45-0.27,3.89l-0.48,4.76c3.61-0.61,7.42-0.96,11.45-0.96
			c2.68,0,5.34,0.09,7.99,0.23l-0.47-3.92c-2.06-17.37-10.36-30.31-24.67-38.47c-5.92-3.38-12.91-5.67-20.3-6.73
			c-2.13-0.34-4.28-0.58-6.37-0.67c-3.35-0.13-6.68,0.01-9.96,0.38c-9.13-5.64-20.11-8.59-32.47-8.59c-14.67,0-29.61,2.13-43.2,6.15
			l-0.84,0.25l0.08,0.88c0.81,8.48,2.36,17.08,3.86,25.4l0.66,3.66c0.34,1.91,1.8,3.29,3.73,3.5c1.91,0.22,3.65-0.79,4.41-2.58
			c2.02-4.76,4.77-11.13,6.36-14.42c8.65-15.88,17.25-16.2,28.47-14.91c5.13,0.59,9.56,2.03,13.34,4.38
			c-0.84,0.26-1.67,0.54-2.49,0.85c-3.49,1.33-6.78,3.15-9.8,5.36c-0.04,0.03-0.08,0.05-0.12,0.08
			c-12.93,8.79-20.96,24.4-20.96,40.74c0,4.2,0.44,7.9,1.35,11.33c2.34,8.8,8.04,16.83,16.07,22.62
			c7.02,5.06,15.39,8.73,24.02,11.93c-0.38,0.6-0.8,1.23-1.19,1.82l-0.02-0.01c-0.16,0.26-0.32,0.5-0.48,0.74l-0.34,0.5l0.01,0.01
			c-5.92,9.05-13.65,13.83-22.44,13.83c-9.08,0-16.38-3.05-26.76-18.62c-1.4-2.21-3.84-6.7-7.27-13.34
			c-0.83-1.61-2.57-2.4-4.33-1.98c-1.77,0.43-2.96,1.93-2.96,3.74c-0.02,9.54,0.59,18.76,1.88,28.2l0.08,0.58l0.54,0.24
			c11,5,25.72,7.86,40.38,7.86c15.49,0,29-5.09,39.11-14.71l0.06,0.03l0.06-0.07c0.74-0.83,1.48-1.6,2.23-2.34
			c0.98,0.35,1.95,0.7,2.92,1.05c-11.58,11.81-18.48,30.17-18.48,49.46c0,16.98,4.93,32.42,13.9,43.73
			c-1.36,0.11-2.73,0.19-4.12,0.19c-0.07,0-0.14,0-0.21,0c0,0,0,0,0,0c-0.02,0-0.04,0-0.06,0c-0.11,0-0.21,0-0.31,0
			c-34.39-0.11-50.06-27.1-50.06-52.19c0-3.97,0.35-8.3,1.07-13.23l0.06-0.39c-6.75-0.83-13.03-2.21-18.56-4.01l-0.02,2.92
			c-0.2,39.63,16.49,66.55,46.98,75.78c6.71,2.04,13.86,3.07,21.23,3.07c1.48,0,2.95-0.05,4.43-0.14c0.09,0,0.18-0.01,0.27-0.01
			c0.46-0.03,0.92-0.06,1.38-0.09c0.53-0.04,1.06-0.08,1.6-0.13c0.19-0.02,0.38-0.03,0.57-0.05c2.4-0.24,4.79-0.59,7.14-1.04
			c9.13,6.28,20.23,9.55,32.76,9.55c14.67,0,29.38-2.86,40.38-7.86l0.54-0.24l0.08-0.58c1.29-9.44,1.9-18.66,1.88-28.2
			c0-1.81-1.19-3.32-2.96-3.75c-1.76-0.42-3.5,0.37-4.33,1.98c-3.43,6.65-5.87,11.14-7.25,13.32
			c-10.4,15.59-17.69,18.64-26.78,18.64c-6.72,0-12.76-2.84-17.79-7.97c3.46-1.49,6.78-3.2,9.88-5.17
			c16.95-10.81,27.06-28.96,27.06-48.56c0-8.26-1.66-16.32-4.93-23.95c-5.11-11.93-13.93-20.09-24.27-26.29
			c1.78-0.49,3.65-0.86,5.58-1.08C266.63,280.11,275.13,280.42,283.65,296.07z M259.02,275.65c13.97,0,28.24,1.99,41.26,5.74
			c-0.82,8.01-2.28,16.17-3.7,24.07l-0.65,3.62c-0.2,1.14-1.08,1.64-1.84,1.73c-0.75,0.08-1.72-0.2-2.17-1.27
			c-2.01-4.74-4.75-11.08-6.38-14.44c-9.16-16.83-18.71-17.15-30.24-15.83c-2.74,0.31-5.36,0.9-7.81,1.75
			c-1.93-1.08-3.91-2.1-5.92-3.07C247.02,276.44,252.87,275.65,259.02,275.65z M145.65,196.78c-1.64,3.38-4.4,9.78-6.43,14.56
			c-0.46,1.08-1.44,1.38-2.2,1.29c-0.77-0.09-1.66-0.59-1.87-1.76l-0.66-3.66c-1.44-7.99-2.92-16.24-3.74-24.35
			c13.17-3.8,27.59-5.81,41.75-5.81c18.03,0,33.01,6.42,43.31,18.55c9.42,11.1,14.61,26.79,14.61,44.18
			c0,9.94-1.71,19.23-5.04,27.64c-0.46,1.11-0.94,2.2-1.41,3.25c-2.16-0.8-4.32-1.6-6.47-2.38c-4.03-1.47-7.94-2.9-11.69-4.37
			c0.4-1.66,0.74-3.57,1.08-5.43c0.1-0.55,0.19-1.1,0.29-1.61l0.01-0.03l-0.01-0.03c0-0.01-0.01-0.02-0.01-0.03
			c1.02-6.12,1.54-12.62,1.54-19.39c0-36.11-10.31-54.11-32.44-56.65C164.6,179.39,154.94,179.72,145.65,196.78z M174.56,233.13
			c0-0.29,0.01-0.57,0.04-1.07c0.02-0.49,0.05-0.98,0.05-1.49c0-9.38,3.52-17.93,9.84-24.33c0.01,0.02,0.02,0.04,0.04,0.06
			c0.76-0.82,1.58-1.6,2.43-2.34c1.05-0.88,2.16-1.71,3.33-2.48c1.61-1.06,3.35-1.99,5.16-2.8c0.54-0.23,1.09-0.45,1.64-0.65
			c0.63-0.23,1.27-0.42,1.92-0.61c5.14,8.93,7.57,22.06,7.57,39.95c0,8.34-0.8,16.27-2.36,23.56l0,0c-0.16,0.68-0.31,1.36-0.48,2.02
			c-0.09-0.02-0.18,0-0.28,0.01c-2.58-1.05-5.09-2.14-7.5-3.29C183.11,253.52,174.56,246.66,174.56,233.13z M135.57,294.16
			c-1.22-9.13-1.8-18.08-1.78-27.32c0-1,0.69-1.52,1.33-1.67c0.64-0.15,1.48,0,1.93,0.88c3.45,6.7,5.93,11.25,7.37,13.53
			c9.65,14.47,17.12,19.6,28.55,19.6c9.97,0,18.63-5.57,25.06-16.11l-0.04-0.03l0.03-0.05c0.51-0.78,0.92-1.4,1.36-2.13
			c3.11,1.13,6.25,2.2,9.35,3.26c1.75,0.6,3.49,1.2,5.22,1.8l-1.54,1.48l-0.08-0.08c-9.72,9.34-22.79,14.27-37.8,14.27
			C160.4,301.59,146.24,298.89,135.57,294.16z M288.57,376.92c1.43-2.25,3.91-6.8,7.36-13.51c0.45-0.88,1.29-1.03,1.93-0.88
			c0.64,0.15,1.33,0.68,1.33,1.67c0.02,9.24-0.56,18.18-1.78,27.32c-10.67,4.72-24.83,7.43-38.96,7.43
			c-38.61,0-55.89-31.04-55.89-61.81c0-19.11,6.93-37.27,18.53-48.65c4.03,1.52,7.93,3.13,11.61,4.93
			c-5.51,9.61-8.43,23.89-8.43,41.31c0,36.38,14.69,61.79,35.72,61.79C271.44,396.53,278.91,391.4,288.57,376.92z M252.09,360.89
			c-4.56,6.1-10.61,11-17.56,14.44c-5.15-10.34-8.11-24.33-8.11-40.6c0-17.12,2.84-31.06,8.2-40.35
			c12.62,6.58,22.3,15.78,25.1,32.15C261.82,338.83,258.97,351.67,252.09,360.89z"
        />
      </g>
    </g>
  </svg>

  <svg
    v-else-if="mode === 'submark'" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 171 224" style="enable-background:new 0 0 171 224;" xml:space="preserve"
  >
    <g>
      <path
        :fill="fill ? '#FFA07D' : 'currentColor'" d="M79.9,203.82c-0.05,0-0.11,0-0.16,0c-34.33,0-49.97-26.89-49.97-51.88c0-3.95,0.35-8.25,1.06-13.15l0.06-0.39
		c-6.71-0.82-12.95-2.2-18.45-3.99l-0.02,2.9c-0.2,39.39,16.39,66.14,46.7,75.32c6.67,2.03,13.77,3.05,21.1,3.05
		c3.58,0,7.14-0.25,10.66-0.72C86.73,211.82,83.03,208.11,79.9,203.82z"
      />
      <path
        :fill="fill ? '#FFA07D' : 'currentColor'" d="M143.75,131.95c-8.37-19.54-26.77-28.92-45.41-36.14c-3.64-1.41-7.32-2.75-10.97-4.08
		c-7.65-2.79-14.88-5.42-21.42-8.56C53.18,77.06,44.69,70.24,44.69,56.8c0-0.29,0.01-0.57,0.04-1.06c0.02-0.49,0.05-0.97,0.05-1.48
		c0-11.88,5.66-22.42,15.54-28.91c2.24-1.48,4.71-2.71,7.32-3.69c-2.58-4.36-5.86-7.63-9.85-9.91c-3.81,1.54-7.42,3.42-10.71,5.67
		c-12.85,8.74-20.84,24.25-20.84,40.49c0,4.17,0.44,7.86,1.34,11.27c2.32,8.74,7.99,16.73,15.97,22.49
		c10.02,7.22,22.77,11.59,35.11,15.82c23.73,8.13,46.15,15.81,50.66,42.15c2.09,12.21-0.74,24.98-7.58,34.14
		c-4.7,6.29-10.99,11.31-18.22,14.75c2.14,4.38,4.67,8.09,7.54,11.01c3.75-1.57,7.34-3.39,10.69-5.52
		c16.84-10.74,26.9-28.79,26.9-48.26C148.64,147.55,147,139.54,143.75,131.95z"
      />
      <g>
        <path
          :fill="fill ? '#FFA07D' : 'currentColor'" d="M10.54,35.56c2.24-5.28,4.84-11.26,6.37-14.43c9-16.54,18.39-16.62,29.35-15.36
			c21.73,2.49,31.3,20.14,31.3,55.25c0,8.43-0.82,16.41-2.37,23.64c4.15,1.66,8.47,3.26,12.97,4.9c2.53,0.92,5.06,1.87,7.6,2.83
			c3.63-8.69,5.51-18.55,5.51-28.97C101.27,29.04,81.42,0,42.64,0C27.57,0,13.05,2.21,0,6.07c0.92,9.62,2.8,19.42,4.47,28.8
			C5.03,37.99,9.3,38.48,10.54,35.56z"
        />
        <path
          :fill="fill ? '#FFA07D' : 'currentColor'" d="M77.91,109.67c-3.59-1.23-7.2-2.48-10.79-3.8c-5.88,9.64-14.03,15.5-24,15.5c-10.29,0-17.57-4.12-27.5-19
			c-1.71-2.7-4.68-8.32-7.27-13.34C7.02,86.44,3.11,87.4,3.1,90.3c-0.02,9.95,0.64,18.95,1.86,27.88
			c10.11,4.59,24.26,7.72,39.7,7.72c16.06,0,28.92-5.46,38.31-14.48C81.29,110.83,79.6,110.25,77.91,109.67z"
        />
      </g>
      <g>
        <path
          :fill="fill ? '#FFA07D' : 'currentColor'" d="M121.62,104.39c1.02-0.2,2.04-0.39,3.12-0.51c10.96-1.26,20.35-1.18,29.35,15.36
			c1.53,3.16,4.13,9.15,6.37,14.43c1.24,2.92,5.51,2.43,6.07-0.69c1.67-9.38,3.55-19.18,4.47-28.8
			c-13.05-3.86-27.57-6.07-42.64-6.07c-5.4,0-10.4,0.62-15.07,1.68C116.15,101.2,118.93,102.73,121.62,104.39z"
        />
        <path
          :fill="fill ? '#FFA07D' : 'currentColor'" d="M162.65,187.13c-2.59,5.02-5.56,10.64-7.27,13.34c-9.93,14.89-17.2,19-27.5,19
			c-21.32,0-34.44-26.54-34.44-60.35c0-17.9,2.51-31.24,7.83-40.37c-4.17-2.04-8.63-3.84-13.26-5.53
			c-12.12,11.74-18.28,29.18-18.28,48.29c0,34.18,20.03,62.49,56.6,62.49c15.44,0,29.59-3.13,39.7-7.72
			c1.22-8.93,1.88-17.93,1.86-27.88C167.89,185.5,163.98,184.55,162.65,187.13z"
        />
      </g>
      <path
        :fill="fill ? '#FFA07D' : 'currentColor'" d="M117.46,52.56l-0.48,4.74c3.59-0.61,7.37-0.95,11.38-0.95c2.67,0,5.31,0.09,7.94,0.23l-0.47-3.9
		c-2.04-17.26-10.29-30.13-24.52-38.23C103,9.71,92.55,7.1,81.88,7.1c-1.7,0-3.38,0.08-5.06,0.2c5.16,3.44,9.54,7.67,13.17,12.47
		c14.52,2.61,27.74,12.55,27.74,28.94C117.73,49.91,117.6,51.13,117.46,52.56z"
      />
    </g>
  </svg>

  <svg
    v-else
    id="Layer_1"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="-100 100 1050 370"
    style="enable-background: new 0 0 883.1 470.1"
    xml:space="preserve"
    :class="{ 'fill-current': fill }"
  >
    <g>
      <g>
        <path
          :fill="fill ? '#136579' : 'currentColor'"
          d="M236.3,421.4h-2.1l-6.9,14.6l-0.1,0.2h2.2l1.8-3.8h7.9l1.8,3.8v0.1h2.3L236.3,421.4L236.3,421.4zM232.2,430.5l3.1-6.6l3,6.6H232.2z"
        />
        <polygon
          :fill="fill ? '#136579' : 'currentColor'"
          points="270.7,421.4 268.6,421.4 268.6,436.2 281.1,436.2 281.1,434.3 270.7,434.3 		"
        />
        <path
          :fill="fill ? '#136579' : 'currentColor'"
          d="M303.3,430.5c0,2.7-1.6,4-4.7,4l0,0c-1.6,0-2.8-0.3-3.5-1c-0.8-0.7-1.2-1.7-1.2-3v-9.1h-2.1v9.4
			c0,1.8,0.6,3.2,1.8,4.2c1.2,1,2.9,1.5,5.1,1.5l0,0c4.3,0,6.8-2.1,6.8-5.7v-9.4h-2.1L303.3,430.5z"
        />
        <polygon
          :fill="fill ? '#136579' : 'currentColor'"
          points="330.7,421.6 330.9,421.5 328.2,421.5 323.5,427.1 318.8,421.5 318.8,421.5 316,421.4 322,428.7
			315.7,436.2 318.4,436.2 323.4,430.2 328.4,436.2 328.4,436.2 331.1,436.2 324.9,428.7 		"
        />
        <path
          :fill="fill ? '#136579' : 'currentColor'"
          d="M353.1,430.5c0,2.7-1.6,4-4.7,4l0,0c-3.1,0-4.7-1.3-4.7-4v-9.1h-2.1v9.4c0,3.6,2.5,5.7,6.9,5.7l0,0
			c2.2,0,3.9-0.5,5-1.5c1.2-1,1.8-2.4,1.8-4.2v-9.4h-2.1L353.1,430.5z"
        />
        <path
          :fill="fill ? '#136579' : 'currentColor'"
          d="M377.1,430.2c2.3-0.4,3.6-1.9,3.6-4.3c0-2.8-1.8-4.4-4.9-4.4H367v14.8h2.1v-6h5.4l4.3,6l0,0h2.6L377.1,430.2zM375.5,428.4h-6.3v-5h6.3c1,0,1.8,0.2,2.3,0.6s0.7,1,0.7,1.9C378.5,427.5,377.5,428.4,375.5,428.4L375.5,428.4z"
        />
        <polygon
          :fill="fill ? '#136579' : 'currentColor'"
          points="397,436.3 399.1,436.3 399.1,429.4 405.6,421.7 405.7,421.5 403,421.5 398.1,427.5 393.1,421.5
			393.1,421.5 390.4,421.5 397,429.4 		"
        />
        <path
          :fill="fill ? '#136579' : 'currentColor'"
          d="M443.3,433c-1.4,1-3,1.5-4.8,1.5l0,0c-1.9,0-3.4-0.5-4.5-1.5c-1.1-1-1.7-2.4-1.7-4.1c0-3.4,2.3-5.7,6-5.7l0,0
			c1.7,0,3.2,0.5,4.6,1.4l0.1,0.1l0.8-1.8h-0.1c-1.5-1-3.4-1.6-5.4-1.6l0,0c-5,0-8.2,3-8.2,7.7c0,4.6,3.2,7.6,8.3,7.6l0,0
			c2.3,0,4.5-0.6,5.9-1.7l0.1-0.1l-0.9-1.8H443.3z"
        />
        <path
          :fill="fill ? '#136579' : 'currentColor'"
          d="M462.4,436.5c4.8,0,8-3.1,8-7.6c0-2.2-0.8-4.2-2.2-5.5c-1.4-1.4-3.5-2.1-5.8-2.1l0,0c-4.8,0-8,3.1-8.1,7.6
			c0,2.2,0.8,4.2,2.2,5.5C458,435.8,460,436.5,462.4,436.5L462.4,436.5z M456.5,428.9c0-3.4,2.4-5.7,5.9-5.7l0,0
			c1.7,0,3.2,0.5,4.3,1.6c1.1,1,1.6,2.4,1.6,4.1c0,3.4-2.4,5.7-5.9,5.7l0,0C458.9,434.6,456.5,432.3,456.5,428.9z"
        />
        <polygon
          :fill="fill ? '#136579' : 'currentColor'"
          points="481.7,421.5 481.7,436.3 494.2,436.3 494.2,434.4 483.8,434.4 483.8,421.5 		"
        />
        <polygon
          :fill="fill ? '#136579' : 'currentColor'"
          points="505,421.5 505,436.3 517.5,436.3 517.5,434.4 507.2,434.4 507.2,421.5 		"
        />
        <polygon
          :fill="fill ? '#136579' : 'currentColor'"
          points="541.2,434.4 530.5,434.4 530.5,429.8 539.9,429.8 539.9,427.9 530.5,427.9 530.5,423.4 541.3,423.4
			541.3,421.5 528.3,421.5 528.3,436.3 541.2,436.3 		"
        />
        <path
          :fill="fill ? '#136579' : 'currentColor'"
          d="M559.8,436.6L559.8,436.6c2.3,0,4.5-0.6,5.9-1.7l0.1-0.1l-0.9-1.8l-0.1,0.1c-1.4,1-3,1.5-4.8,1.5l0,0
			c-1.9,0-3.4-0.5-4.5-1.5c-1.1-1-1.7-2.4-1.7-4.1c0-3.4,2.3-5.7,6-5.7l0,0c1.7,0,3.2,0.5,4.6,1.4l0.1,0.1l0.8-1.8h-0.1
			c-1.5-1-3.4-1.6-5.4-1.6l0,0c-5,0-8.2,3-8.2,7.7C551.5,433.6,554.8,436.6,559.8,436.6z"
        />
        <polygon
          :fill="fill ? '#136579' : 'currentColor'"
          points="575.4,423.4 581.2,423.4 581.2,436.3 583.3,436.3 583.3,423.4 589.2,423.4 589.2,421.6 575.4,421.6
					"
        />
        <rect
          x="600"
          y="421.6"
          :fill="fill ? '#136579' : 'currentColor'"
          width="2.1"
          height="14.8"
        />
        <path
          :fill="fill ? '#136579' : 'currentColor'"
          d="M621.5,436.6c4.8,0,8-3.1,8.1-7.6c0-2.2-0.8-4.2-2.2-5.5c-1.4-1.4-3.5-2.1-5.8-2.1l0,0c-4.8,0-8,3.1-8,7.6
			C613.4,433.5,616.6,436.6,621.5,436.6L621.5,436.6z M615.6,429c0-3.4,2.4-5.7,5.9-5.7l0,0c1.7,0,3.2,0.5,4.3,1.6
			c1.1,1,1.6,2.4,1.6,4.1s-0.6,3.1-1.6,4.1c-1.1,1-2.5,1.5-4.3,1.5l0,0C618,434.6,615.6,432.4,615.6,429z"
        />
        <polygon
          :fill="fill ? '#136579' : 'currentColor'"
          points="652.5,432.9 643.6,421.6 643.6,421.6 640.8,421.6 640.8,436.4 642.8,436.4 642.8,424 652.6,436.3
			652.6,436.4 654.6,436.4 654.6,421.6 652.5,421.6 		"
        />
        <path
          :fill="fill ? '#136579' : 'currentColor'"
          d="M295.5,454.8h-2.1l-6.9,14.6l-0.1,0.2h2.2l1.8-3.8h7.9l1.8,3.8v0.1h2.3L295.5,454.8L295.5,454.8zM291.4,463.9l3.1-6.6l3,6.6H291.4z"
        />
        <polygon
          :fill="fill ? '#136579' : 'currentColor'"
          points="315.4,454.8 313.2,454.8 313.2,469.6 325.7,469.6 325.7,467.7 315.4,467.7 		"
        />
        <polygon
          :fill="fill ? '#136579' : 'currentColor'"
          points="338.7,454.8 336.5,454.8 336.5,469.6 349,469.6 349,467.7 338.7,467.7 		"
        />
        <rect
          x="357"
          y="462.7"
          :fill="fill ? '#136579' : 'currentColor'"
          width="9.4"
          height="1.8"
        />
        <rect
          x="377.2"
          y="454.8"
          :fill="fill ? '#136579' : 'currentColor'"
          width="2.1"
          height="14.8"
        />
        <polygon
          :fill="fill ? '#136579' : 'currentColor'"
          points="403,466.2 394.2,454.9 394.1,454.8 391.3,454.8 391.3,469.6 393.4,469.6 393.4,457.2 403.1,469.6
			403.1,469.6 405.1,469.6 405.1,454.8 403,454.8 		"
        />
        <path
          :fill="fill ? '#136579' : 'currentColor'"
          d="M429.6,466.4c-1.4,1-3,1.5-4.8,1.5l0,0c-1.9,0-3.4-0.5-4.5-1.5c-1.1-1-1.7-2.4-1.7-4.1c0-3.4,2.3-5.7,6-5.7
			l0,0c1.7,0,3.2,0.5,4.6,1.4l0.1,0.1l0.8-1.8H430c-1.5-1-3.4-1.6-5.4-1.6l0,0c-5,0-8.2,3-8.2,7.7c0,2.3,0.8,4.2,2.2,5.6
			c1.5,1.4,3.5,2.1,6,2.1l0,0c2.3,0,4.5-0.6,5.9-1.7l0.1-0.1l-0.9-1.8L429.6,466.4z"
        />
        <polygon
          :fill="fill ? '#136579' : 'currentColor'"
          points="443.5,454.9 441.4,454.9 441.4,469.6 453.9,469.6 453.9,467.8 443.5,467.8 		"
        />
        <path
          :fill="fill ? '#136579' : 'currentColor'"
          d="M476.1,463.9c0,2.7-1.6,4-4.7,4l0,0c-1.6,0-2.8-0.3-3.5-1c-0.8-0.7-1.2-1.7-1.2-3v-9.1h-2.1v9.4
			c0,3.6,2.5,5.7,6.8,5.7l0,0c4.3,0,6.8-2.1,6.8-5.7v-9.4h-2.1L476.1,463.9z"
        />
        <path
          :fill="fill ? '#136579' : 'currentColor'"
          d="M496.4,461.2c-3.4-0.2-4.7-0.8-4.7-2.1c0-0.7,0.4-1.4,1.1-1.8c0.7-0.4,1.7-0.7,3-0.7l0,0c2,0,3.8,0.5,5.2,1.5
			l0.1,0.1l1-1.8l-0.1-0.1c-1.6-1.2-3.6-1.8-6-1.8l0,0c-1.9,0-3.5,0.4-4.7,1.2c-1.1,0.8-1.7,1.9-1.7,3.3c0,2.7,1.8,3.8,6.4,4.2
			c3.4,0.2,4.7,0.8,4.7,2.1c0,2.1-2.5,2.5-4.5,2.5l0,0c-2.3,0-4.4-0.7-6.1-1.9l-0.1-0.1l-1,1.7l0.1,0.1c1.8,1.4,4.3,2.2,7.1,2.2l0,0
			c2.1,0,3.8-0.4,5-1.2c1.2-0.8,1.8-1.9,1.8-3.4C502.8,462.6,501,461.5,496.4,461.2z"
        />
        <rect
          x="513.8"
          y="454.9"
          :fill="fill ? '#136579' : 'currentColor'"
          width="2.1"
          height="14.8"
        />
        <polygon
          :fill="fill ? '#136579' : 'currentColor'"
          points="534.5,467 529.1,455 529.1,454.9 526.7,454.9 533.4,469.7 535.5,469.7 542.2,454.9 539.9,454.9 		"
        />
        <polygon
          :fill="fill ? '#136579' : 'currentColor'"
          points="552.9,469.7 565.8,469.7 565.8,467.8 555.1,467.8 555.1,463.2 564.5,463.2 564.5,461.3 555.1,461.3
			555.1,456.8 565.8,456.8 565.8,454.9 552.9,454.9 		"
        />
        <path
          :fill="fill ? '#136579' : 'currentColor'"
          d="M605.8,459.4c0-2.8-1.8-4.4-4.9-4.4h-8.8v14.8h2.1v-6h5.4l4.3,6l0,0h2.6l-4.4-6.1
			C604.5,463.3,605.8,461.7,605.8,459.4z M600.5,461.9h-6.3v-5h6.3c1,0,1.8,0.2,2.3,0.6c0.5,0.4,0.7,1,0.7,1.9
			C603.6,461.1,602.6,461.9,600.5,461.9L600.5,461.9z"
        />
        <polygon
          :fill="fill ? '#136579' : 'currentColor'"
          points="617.4,469.8 630.3,469.8 630.3,467.9 619.5,467.9 619.5,463.2 628.9,463.2 628.9,461.4 619.5,461.3
			619.5,456.9 630.3,456.9 630.3,455 617.4,455 		"
        />
        <path
          :fill="fill ? '#136579' : 'currentColor'"
          d="M647.7,461.3c-3.4-0.2-4.7-0.8-4.7-2.1c0-0.7,0.4-1.4,1.1-1.8c0.7-0.4,1.7-0.7,3-0.7l0,0c2,0,3.8,0.5,5.2,1.5
			l0.1,0.1l1-1.8l-0.1-0.1c-1.6-1.2-3.6-1.8-6-1.8l0,0c-1.9,0-3.5,0.4-4.7,1.2c-1.1,0.8-1.7,1.9-1.7,3.3c0,2.7,1.8,3.8,6.4,4.2
			c3.4,0.2,4.7,0.8,4.7,2.1c0,0.8-0.4,1.5-1.1,1.9c-0.8,0.4-1.9,0.7-3.4,0.7l0,0c-2.3,0-4.4-0.7-6.1-1.9l-0.1-0.1l-1,1.7l0.1,0.1
			c1.8,1.4,4.3,2.2,7.1,2.2l0,0c2.1,0,3.8-0.4,5-1.2c1.2-0.8,1.8-1.9,1.8-3.4C654.2,462.8,652.4,461.6,647.7,461.3z"
        />
        <path
          :fill="fill ? '#136579' : 'currentColor'"
          d="M672.5,454.8L672.5,454.8c-4.8,0-8.1,3.1-8.1,7.6c0,2.2,0.8,4.2,2.2,5.5c1.4,1.4,3.5,2.1,5.8,2.1l0,0
			c2.4,0,4.4-0.7,5.8-2.1c1.4-1.4,2.2-3.3,2.2-5.5C680.6,457.8,677.3,454.8,672.5,454.8z M672.5,468.1L672.5,468.1
			c-1.7,0-3.2-0.5-4.3-1.6c-1.1-1-1.6-2.4-1.6-4.1c0-3.4,2.4-5.7,5.9-5.7l0,0c1.7,0,3.2,0.5,4.3,1.6c1.1,1,1.6,2.4,1.6,4.1
			C678.4,465.8,676,468.1,672.5,468.1z"
        />
        <path
          :fill="fill ? '#136579' : 'currentColor'"
          d="M705.5,459.4c0-1.4-0.4-2.5-1.3-3.2c-0.8-0.8-2-1.1-3.6-1.1h-8.8v14.8h2.1v-6h5.4l4.3,6l0,0h2.6l-4.4-6.1
			C704.2,463.3,705.5,461.8,705.5,459.4z M700.3,461.9H694v-5h6.3c2,0,3,0.8,3,2.5S702.3,461.9,700.3,461.9L700.3,461.9z"
        />
        <polygon
          :fill="fill ? '#136579' : 'currentColor'"
          points="715.9,456.9 721.8,456.9 721.8,469.8 723.9,469.8 723.9,456.9 729.8,456.9 729.8,455 715.9,455 		"
        />
        <path
          :fill="fill ? '#136579' : 'currentColor'"
          d="M53.3,167.6c-2.4-0.9-4.7-1.8-7-2.6c-5-1.8-9.7-3.5-13.9-5.6c-7.6-3.6-14.5-8.3-14.5-18.3c0-0.2,0-0.4,0-0.6
			v-0.1c0-0.3,0-0.6,0-0.9c0-8.2,3.9-15.4,10.7-19.9c4.2-2.8,9.5-4.3,15-4.3c11.8,0,24.3,7.2,24.3,20.6c0,0.8-0.1,1.7-0.2,2.6
			l-0.2,1.9l9.3-0.1l-0.2-1.7c-1.3-10.6-6.3-18.5-15.1-23.5c-5.1-2.9-11.6-4.5-18.2-4.5c-7.9,0-15.6,2.3-21.6,6.4
			c-7.9,5.4-12.8,14.9-12.8,24.9c0,2.6,0.3,4.8,0.8,6.9c1.4,5.3,4.9,10.2,9.7,13.7c6.3,4.5,14.4,7.3,22.2,10
			c7.5,2.6,15.3,5.3,21.5,9.5c6.8,4.7,10.8,10.9,12.2,18.9c1.4,8.3-0.5,16.9-5.2,23.1c-6.3,8.5-16.9,13.5-28.3,13.5
			c-23.2,0-33.7-18.1-33.7-34.9c0-2.6,0.2-5.5,0.7-8.7l0.2-1.6l-9-1v1.6c-0.1,24.7,10.2,41.4,29,47.1c4.2,1.3,8.6,1.9,13.2,1.9
			c9.4,0,18.6-2.6,26-7.3c10.4-6.7,16.6-17.8,16.6-29.9c0-5.1-1-10.1-3-14.8C76.6,178,65.1,172.1,53.3,167.6z"
        />
        <path
          :fill="fill ? '#136579' : 'currentColor'"
          d="M100.1,228.4c10.6-26.5,32.7-45.5,65.7-56.3l24,58.2c1.1,2.7,1.7,4.7,1.7,5.8c0,0.5,0,0.6-2.4,0.7h-1.5v3.8
			h20.5v-3.8h-1.5c-3.8,0-4.6,0-7.3-6.6l-25-60.6c1-0.3,2-0.5,3.2-0.8l1.7-0.4l-1.6-3.9l-1.3,0.3c-0.7,0.2-1.4,0.4-2.1,0.5l-1.5,0.4
			l-22.3-54.1l-0.4-0.9h-9.6l1.7,4.5l-49,119.2c-1.1,2.2-1.5,2.3-4.9,2.3h-1.5v3.8H101v-3.8h-2.3c-0.9,0-1.4,0-1.6,0
			c0.1-1.2,1.2-3.8,2.1-6.2L100.1,228.4z M144.5,120.4l19.6,47.7c-22.3,7.3-40.6,19.1-53.3,34.1L144.5,120.4z"
        />
        <path
          :fill="fill ? '#136579' : 'currentColor'"
          d="M221.9,236.6h-3.2v4h15.7v-4h-2.7c-2.9,0-2.9,0-3.1-5.8v-99.4l39.5,100.3l0.4,1.2c0.3,0.8,1.1,3,1.1,3.5
			c-0.2,0.1-0.7,0.2-1.9,0.2h-2v4h19.5v-4h-1.5c-4.1,0-4.1,0-5.6-3.8l-1-2.4V119.6c0-5.2,0-5.2,2.6-5.2h3.2v-4h-15.7v4h2.7
			c3,0,3,0,3.1,5.2v100.1l-39.5-100.3l-0.4-1.2c-0.3-0.9-1.1-3.1-1.1-3.5c0.2-0.1,0.6-0.2,1.9-0.2h2v-4h-19.5v4h1.5
			c4.1,0,4.1,0,5.6,3.8l1,2.4v110.1C224.5,236.6,224.5,236.6,221.9,236.6z"
        />
        <path
          :fill="fill ? '#136579' : 'currentColor'"
          d="M562.9,214.6c1-3.5,1.4-7.6,1.6-9.2c0.1-0.9,0.1-1.8,0.1-3v-1.6c0-3,0-6.1,0-8.8v-0.8c0-2.4,0-5.3,0-8.1
			l0.1-64.8c0-3.9,0-3.9,4.2-3.9h1.5v-3.8H554v3.8h1.5c4.3,0,4.3,0,4.3,3.6l-0.1,23c-0.2,32,0,43.1,0.2,65.1
			c-0.2,4.7-1.6,9.7-3.7,13.9c-6.4,11.3-16.6,16.8-27.3,14.9c-11.3-2-20.2-11.9-22.3-24.6c-0.4-2.3-0.3-4.1-0.3-18.2v-0.4
			c0.1-12.8,0.2-34.2,0.2-74c0-1.5,0.1-2.5,0.4-2.8c0.6-0.6,2.9-0.5,3.7-0.5h1.5v-3.8H406.5l-1.2,20.4h3.6l0.2-1.3
			c1.5-9.3,3.2-15,14.1-15h10.2c4,0,4,0,4,4.2V232c0,4.7-0.2,4.7-4.2,4.7h-1.5v3.8h20.4v-3.8h-1.5c-4.2,0-4.2,0-4.2-4.7V119
			c0-4.3,0-4.3,4-4.3h35.8c6,0,9.4,1.5,11.4,5.2c0,10.7,0,19.9,0,27.9v0.3c0,32.5-0.1,44.7,0.8,58.7c1,16.4,14.7,33.9,33.4,33.9h0.5
			c3.1-0.1,6.3-0.7,9.5-1.9c2.9-1.1,5.7-2.7,8.3-4.7C556,229.5,560.9,222.1,562.9,214.6z"
        />
        <path
          :fill="fill ? '#136579' : 'currentColor'"
          d="M677.5,230.1l-25-60.6c0.9-0.3,2-0.5,3.2-0.8l1.7-0.4l-1.6-3.9l-1.3,0.3c-0.7,0.2-1.4,0.4-2.1,0.5l-0.3,0.1
			c-0.4,0.1-0.8,0.2-1.2,0.3l-22.3-54.1l-0.4-0.9h-9.6l1.8,4.5l-49.2,119.4c-1.1,2.2-1.5,2.3-4.9,2.3h-1.5v3.8h14.3v-3.8h-2.3
			c-0.9,0-1.4,0-1.6,0c0.1-1.2,1.2-3.8,2.1-6.2l0.8-2.1c10.6-26.5,32.7-45.5,65.7-56.3l24,58.2c1.1,2.7,1.7,4.7,1.7,5.8
			c0,0.5,0,0.6-2.4,0.7h-1.5v3.8h20.5v-3.8h-1.5C681,236.7,680.3,236.7,677.5,230.1z M642.4,168.1c-22.3,7.3-40.6,19.1-53.3,34.1
			l33.7-81.8L642.4,168.1z"
        />
        <path
          :fill="fill ? '#136579' : 'currentColor'"
          d="M813.5,236.8c-16.3,5.9-30.9-2.2-47.9-16.1l-6.1-5c-7.7-6.3-13-10.7-17.9-14.3c1.8,0.2,3.6,0.3,4.9,0.3
			c18.7,0,35.5-10.1,43.6-26.3c4.7-9.4,5.9-20.2,3.3-31.1c-1.7-7.5-6.1-15.5-11.3-20.8c-9.1-9.1-18.7-13.2-31.1-13.2h-56.8v4.3h2.1
			c1.5,0,2.6,0,3,0.4c0.4,0.4,0.7,1.6,0.7,3.9v113c0,4.7-0.7,4.7-4.3,4.7h-1.5v3.8h20.4v-3.8h-1.5c-3.3,0-4.2,0-4.2-4.7V188
			c8,2.4,18.6,11.9,28.9,21.1c5.4,4.8,11,9.8,16,13.5c14.1,10.6,30.7,21.4,46.5,21.4c5.2,0,10.1-1.2,14.7-3.4l1.3-0.7l-1.7-3.6
			L813.5,236.8z M709,119c0-3.8,0.6-4.3,9.1-4.3h4.7c10.5,0,12.9,0,26.1,0.4c9.1,0.3,17.3,3.4,23.1,8.8c5.8,5.4,7.5,7.7,11,15.3
			c2.7,8.5,5.1,18.9-0.6,33.4c-6,14.1-21.4,24.6-36.6,24.9c-4.3,0.1-8.3-0.5-12.7-2C732,194.6,716,182,709,182.4V119z"
        />
        <path
          :fill="fill ? '#136579' : 'currentColor'"
          d="M869.1,110.5v3.8h1.5c0.8,0,1.4,0,1.8,0.1c-0.1,0.3-0.3,1-1,2.5L845,165.4l-26.6-49c-0.6-1.3-0.5-1.7-0.5-1.8
			c0.1-0.1,0.6-0.3,3.1-0.3h1.5v-3.8H802v3.8h1.5c3,0,3.5,0,4.9,2.4l30.3,55.6v60.2c0,3.7-1,4.2-4.3,4.2h-1.5v3.8h20.4v-3.8h-1.5
			c-3.3,0-4.2-0.6-4.2-4.3v-63l28.6-52.6c1.5-1.9,2.8-2.5,5.5-2.5h1.5v-3.8L869.1,110.5L869.1,110.5z"
        />
        <path
          :fill="fill ? '#136579' : 'currentColor'"
          d="M399.9,208.2l-6.8-0.9l-0.2,1c-1.2,6.1-4.2,11.9-8.5,16.9c-4.3,4.9-9.7,8.6-15.6,10.6
			c-12,4.1-26.4,2.2-37.5-5c-12.3-7.9-21-20.3-24.9-35.7c-3.6-13.9-3.2-29.6,1.2-44.1c6.3-20.9,17.9-29.7,24.3-33.1
			c11.4-6.1,26.8-5.5,39.4,1.5c10,5.7,17.3,15.5,19.5,26.3l0.1,0.5l8.2-2.6l-0.2-0.4c-4.9-13.6-15.9-24.8-29.5-30
			c-14-5.3-30.6-3.9-43.4,3.8c-10.6,6.4-19.2,16.2-24.9,28.4c-5.4,11.7-7.8,24.6-6.9,37.3c0.3,4.6,1.1,9.1,2.3,13.4
			c3.4,12.2,10.1,22.9,19.4,31c9.5,8.3,21.1,13.3,33.5,14.4c1.5,0.1,3,0.2,4.5,0.2c7,0,14-1.5,20.2-4.2
			c12.6-5.6,21.9-16.1,25.7-28.8L399.9,208.2z"
        />
        <path
          :fill="fill ? '#136579' : 'currentColor'"
          d="M244.8,311c-13,0-17.7,10.3-17.7,19.9c0,8.9,4.4,19.4,16.7,19.4c4.1,0,7.7-0.6,11.8-2.1l0.5-0.2l0.1-0.5
			c0.4-3.4,0.5-5.1,0.7-8.6v-0.1c0.1-0.6-0.5-1.2-1.1-1.2l0,0c-0.5,0-0.9,0.3-1.1,0.8c-0.9,3.1-2.2,5.4-2.2,5.4
			c-1.7,3.2-5.5,4.4-8.2,4.4c-7.1,0-11.5-6.9-11.5-17.9c0-11.3,3.7-17,11-17c2.2,0,7.5,0.3,8.7,3.3c0.1,0.5,1.1,3.8,1.4,5.5
			c0.1,0.6,0.6,1,1.2,1l0,0c0.6,0,1.1-0.4,1.2-1s0.2-1.2,0.2-1.8v-0.1c0.3-2.4,0.7-3.8,0.9-6.8l0.1-0.8l-0.7-0.2
			C252.3,311.5,248.7,311,244.8,311z"
        />
        <path
          :fill="fill ? '#136579' : 'currentColor'"
          d="M469,311c-13,0-17.7,10.3-17.7,19.9c0,8.9,4.4,19.4,16.7,19.4c4.1,0,7.7-0.6,11.8-2.1l0.5-0.2l0.1-0.5
			c0.4-3.4,0.5-5.1,0.7-8.6v-0.1c0.1-0.6-0.5-1.2-1.1-1.2l0,0c-0.5,0-0.9,0.3-1.1,0.8c-0.9,3.1-2.2,5.4-2.2,5.4
			c-1.7,3.2-5.5,4.4-8.2,4.4c-7.1,0-11.5-6.9-11.5-17.9c0-11.3,3.7-17,11-17c2.2,0,7.5,0.3,8.7,3.3c0.1,0.5,1.1,3.8,1.4,5.5
			c0.1,0.6,0.6,1,1.2,1l0,0c0.6,0,1.1-0.4,1.2-1s0.2-1.2,0.2-1.8v-0.1c0.3-2.4,0.7-3.8,0.9-6.8l0.1-0.8l-0.7-0.2
			C476.5,311.5,472.9,311,469,311z"
        />
        <path
          :fill="fill ? '#136579' : 'currentColor'"
          d="M285.2,349.9h11v-1.7l-0.5-0.2c-2-1-3.1-1.6-4-2.5s-0.8-1.1-0.7-1.7l2.4-7c3.9,0.2,7.7,0.2,12,0
			c1.1,3.3,2.1,6.8,3.2,10.4c0,0.1,0.1,0.2,0.1,0.3c-0.1,0-0.2,0-0.4,0.1l-3.9,0.3v2h13.2v-2l-2.5-0.3c-0.6-0.1-0.6-0.1-0.7-0.5l0,0
			c-3.8-11.1-7.6-22.9-10.7-32.3l-1.4-4.1v-0.1l-2.5,0.7l-10.7,31.4c-0.5,1.3-1.1,2.3-1.7,3.2c-0.7,1-1.4,1.8-2,2.3l-0.3,0.3v1.4
			H285.2z M299.5,318.9l1.4,4.1c1.4,4.1,2.6,7.9,3.9,11.7c-3.7,0.1-7.1,0.1-10.5,0L299.5,318.9z"
        />
        <path
          :fill="fill ? '#136579' : 'currentColor'"
          d="M350.3,347.6l-3.1,0.3v2h14.3v-2l-4.7-0.3c-0.5,0-0.5,0-0.5-0.5c-0.1-3.7-0.2-8.8-0.2-14.4
			c1.3,0.1,2.6,0.2,3.9,0.2c7.2,0,11.7-4.4,11.7-11.5c0-6.2-4.4-10-11.4-10h-13.1v2l3.1,0.3c0.4,0.1,0.4,0.1,0.4,0.4
			c0.2,13,0.2,23.6,0,33.3C350.7,347.6,350.7,347.6,350.3,347.6z M356.2,313.7c1.1-0.1,2.3-0.2,3.3-0.2c4,0,6.5,3.2,6.5,8.3
			c0,5.9-1.9,9-5.7,9c-1.1,0-2.6-0.2-4.3-0.7C356.1,323.7,356.1,317.9,356.2,313.7z"
        />
        <path
          :fill="fill ? '#136579' : 'currentColor'"
          d="M533.7,347.2c0,0.1,0.1,0.3,0.1,0.3c-0.1,0-0.2,0-0.3,0.1l-3.9,0.3v2h13.2v-2l-2.5-0.3
			c-0.6-0.1-0.6-0.1-0.7-0.5l0,0c-3.8-11.1-7.6-22.8-10.7-32.2l-1.4-4.2v-0.1l-2.5,0.7l-10.7,31.4c-0.5,1.4-1.1,2.3-1.7,3.2
			c-0.7,1-1.4,1.8-2,2.3l-0.3,0.3v1.6h11v-1.7l-0.5-0.2c-2-1-3.1-1.6-4-2.5s-0.8-1.1-0.7-1.7l2.4-7c3.9,0.2,7.8,0.2,12,0
			C531.6,340.2,532.7,343.7,533.7,347.2z M519.3,334.6l5.3-15.7c0.4,1.3,0.9,2.7,1.3,4l0.2,0.7c1.3,3.8,2.5,7.4,3.7,11.1
			C526.1,334.8,522.7,334.8,519.3,334.6z"
        />
        <path
          :fill="fill ? '#136579' : 'currentColor'"
          d="M596.7,317c0-0.8,0.2-1.3,0.6-1.9c0.4-0.7,0.9-1.4,1.4-2l0.2-0.3v-1.6h-10v1.8l0.5,0.2
			c1.4,0.6,2.6,1.3,3.8,2.1c0.9,0.6,1.2,1,1.2,1.9v26.5c-5.3-9.3-10.9-19.5-17.6-32.1l-0.3-0.5h-8.3v2l2.6,0.3c0.1,0,0.2,0,0.2,0
			v30.5c0,0.6-0.2,1.1-0.6,1.8c-0.5,0.8-1,1.5-1.5,2.1l-0.2,0.3v1.4H579v-1.7l-0.5-0.2c-1.7-0.8-3.1-1.6-4.3-2.4
			c-0.8-0.5-1.1-0.9-1.1-1.8v-27.5c6.9,11.9,12.9,22.7,18.3,33l0.2,0.5h4.8V317H596.7z"
        />
        <path
          :fill="fill ? '#136579' : 'currentColor'"
          d="M652.8,347.1c-3.6-10.6-7.1-21.4-10.5-31.8l-1.5-4.7v-0.1l-2.5,0.7l-10.7,31.4c-0.5,1.4-1.1,2.3-1.7,3.2
			c-0.7,1-1.4,1.8-2,2.3l-0.3,0.3v1.6h11v-1.7l-0.5-0.2c-2-1-3-1.6-4-2.5c-0.9-0.9-0.8-1.1-0.7-1.7l2.4-7c3.9,0.2,7.8,0.2,12,0
			c1.1,3.3,2.1,6.7,3.2,10.4c0,0.1,0.1,0.3,0.1,0.3c-0.1,0-0.2,0-0.3,0.1l-3.9,0.3v2H656v-2l-2.5-0.3
			C652.9,347.5,652.9,347.5,652.8,347.1z M632.5,334.6l5.3-15.7l1.3,4c1.4,4.3,2.6,7.9,3.9,11.7C639.4,334.8,636,334.8,632.5,334.6z
			"
        />
        <path
          :fill="fill ? '#136579' : 'currentColor'"
          d="M162.3,454.9h-2.1l-6.9,14.6l-0.1,0.2h2.2l1.8-3.8h7.9l1.8,3.8v0.1h2.3L162.3,454.9L162.3,454.9z M158.1,464
			l3.1-6.6l3,6.6H158.1z"
        />
        <path
          :fill="fill ? '#136579' : 'currentColor'"
          d="M186.6,454.9H180v14.8h6.6l0,0c4.6,0,7.6-2.9,7.6-7.4c0-2.2-0.7-4.1-2.1-5.4
			C190.8,455.6,188.9,454.9,186.6,454.9z M186.4,467.8L186.4,467.8h-4.3v-11h4.3c1.7,0,3.1,0.5,4.1,1.5s1.5,2.4,1.5,4
			C192,465.7,189.9,467.8,186.4,467.8z"
        />
        <path
          :fill="fill ? '#136579' : 'currentColor'"
          d="M216.8,464c0,2.7-1.6,4-4.7,4l0,0c-3.1,0-4.7-1.3-4.7-4v-9.1h-2.1v9.4c0,1.8,0.6,3.2,1.8,4.2
			c1.2,1,2.9,1.5,5.1,1.5l0,0c4.3,0,6.8-2.1,6.8-5.7v-9.4h-2.1L216.8,464z"
        />
        <polygon
          :fill="fill ? '#136579' : 'currentColor'"
          points="232.8,455 230.7,455 230.7,469.7 243.2,469.7 243.2,467.8 232.8,467.8 		"
        />
        <polygon
          :fill="fill ? '#136579' : 'currentColor'"
          points="249.2,456.8 255,456.8 255,469.7 257.1,469.7 257.1,456.8 263,456.9 263,455 249.2,455 		"
        />
      </g>
    </g>
  </svg>
</template>
